body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

.video-player {
  width: 100%;
  height: 360px;
}

.custom-controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.play-pause-btn {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  pointer-events: auto;
}

.progress-bar {
  flex-grow: 1;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  pointer-events: auto;
  position: relative;
}

.progress {
  height: 100%;
  background-color: red;
  width: 0%;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}

.youtube-player {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
  pointer-events: none; /* Evitar la interacción del usuario */
}

.hide-elements::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: all;
  z-index: 3;
}

.ytp-watermark {
  display: 'none !important';
}

.yt-uix-sessionlink {
  display: 'none !important';
}
